import styled from "styled-components"

export const TrackmeetLogoVert = styled.img`
  width: 6vw;
  height: 68vh;
`

export const TrackmeetLandingImg = styled.img`
  max-height: 92vh;
  max-width: 90vw;
`
